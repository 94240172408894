import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Initialize Stripe outside the component
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function BuyCredits({ onClose }) {
  const initiateCheckout = async (priceId, mode) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ priceId, mode }),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to create checkout session');
      }

      const checkoutSession = await response.json();

      // Get the Stripe instance
      const stripe = await stripePromise;

      // Redirect to Checkout
      const { error } = await stripe.redirectToCheckout({ sessionId: checkoutSession.id });
      if (error) {
        console.error('Stripe redirect error:', error);
      }
    } catch (error) {
      console.error('Error initiating checkout:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Back Button at the Top */}
      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={onClose}
        >
          Back
        </Button>
      </Box>

      {/* Products Grid */}
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">

        {/* Trial Pack */}
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: 'center', borderRadius: 2, flexGrow: 1 }}
          >
            <Typography variant="h5" gutterBottom>
              Trial 
            </Typography>
            <Typography variant="h6" gutterBottom>
              $8
            </Typography>
            <Typography variant="body1" gutterBottom>
              Train 1 model, and create 10 images
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => initiateCheckout('price_1QLzErFhmSaLvSL4FkEwjzwM', 'payment')}
              sx={{ marginTop: 2, borderRadius: 1 }}
              fullWidth
            >
              Choose
            </Button>
          </Paper>
        </Grid>

        {/* Image only pack */}
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: 'center', borderRadius: 2, flexGrow: 1 }}
          >
            <Typography variant="h5" gutterBottom>
              Image Only 
            </Typography>
            <Typography variant="h6" gutterBottom>
              $15
            </Typography>
            <Typography variant="body1" gutterBottom>
              Create 100 images, not including model training
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => initiateCheckout('price_1QK9njFhmSaLvSL47UzVmj8Q', 'payment')}
              sx={{ marginTop: 2, borderRadius: 1 }}
              fullWidth
            >
              Choose
            </Button>
          </Paper>
        </Grid>

        {/* Commenting out Small Pack for now  

        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: 'center', borderRadius: 2, flexGrow: 1 }}
          >
            <Typography variant="h5" gutterBottom>
              Small 
            </Typography>
            <Typography variant="h6" gutterBottom>
              $15
            </Typography>
            <Typography variant="body1" gutterBottom>
              Train 1 model, and create 50 images
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => initiateCheckout('price_1QIXOVFhmSaLvSL4I4EVJamc', 'payment')}
              sx={{ marginTop: 2, borderRadius: 1 }}
              fullWidth
            >
              Choose
            </Button>
          </Paper>
        </Grid>

        */}

        
        {/* Standard Plan */}
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: 'center', borderRadius: 2, flexGrow: 1 }}
          >
            <Typography variant="h5" gutterBottom>
              Medium 
            </Typography>
            <Typography variant="h6" gutterBottom>
              $39
            </Typography>
            <Typography variant="body1" gutterBottom>
              Train 3 models and create 250 images
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => initiateCheckout('price_1QK97AFhmSaLvSL40gk4pUHC', 'payment')}
              sx={{ marginTop: 2, borderRadius: 1 }}
              fullWidth
            >
              Choose
            </Button>
          </Paper>
        </Grid>

        {/* Premium Plan */}
        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: 'center', borderRadius: 2, flexGrow: 1 }}
          >
            <Typography variant="h5" gutterBottom>
              Pro 
            </Typography>
            <Typography variant="h6" gutterBottom>
              $99
            </Typography>
            <Typography variant="body1" gutterBottom>
              Train 6 models and create 500 images
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => initiateCheckout('price_1QK9I5FhmSaLvSL4XefdnMEM', 'payment')}
              sx={{ marginTop: 2, borderRadius: 1 }}
              fullWidth
            >
              Choose
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BuyCredits;