import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

function GetStartedOverlay({ setShowBuyCredits }) {
    return (
        <Box sx={{ padding: 2, maxWidth: '100%', margin: '0 auto' }}>
            <Grid container spacing={2}>
                {/* Single Column: Text */}
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        <strong>Welcome!</strong> Lets get started:
                    </Typography>
                    <Box component="div">
                        <ul>
                            <li>
                                <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                                    <strong>Step 1:</strong> Train a model on images of one person or pet.   
                                </Typography>
                            </li>
                         
                            
                            <br/>
                            <li>
                                <Typography variant="body1" sx={{ fontSize: '1.2rem' }}>
                                    <strong>Step 2:</strong> Create your own AI images
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default GetStartedOverlay; 