import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Alert,
} from '@mui/material';

function ChangePassword({ onClose }) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError('New passwords do not match');
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/change-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    currentPassword,
                    newPassword,
                }),
            });
            const data = await response.json();
            if (response.ok) {
                setSuccess('Password changed successfully');
                setError('');
                // Optionally, close the dialog after success
                // onClose();
            } else {
                setError(data.error || 'An error occurred.');
            }
        } catch (error) {
            console.error('Change Password Error:', error);
            setError('An error occurred.');
        }
    };

    return (
        <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>
                Change Password
            </DialogTitle>
            <form onSubmit={handleChangePassword}>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {success}
                        </Alert>
                    )}
                    <TextField
                        fullWidth
                        label="Current Password"
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        margin="normal"
                        required
                        disabled={success}
                    />
                    <TextField
                        fullWidth
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        margin="normal"
                        required
                        disabled={success}
                    />
                    <TextField
                        fullWidth
                        label="Confirm New Password"
                        type="password"
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        margin="normal"
                        required
                        disabled={success}
                    />
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    {success ? (
                        <Button onClick={onClose} variant="contained">
                            Done
                        </Button>
                    ) : (
                        <>
                            <Button onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Change Password
                            </Button>
                        </>
                    )}
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ChangePassword; 